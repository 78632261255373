<template>
    <div 
        class="custom-input position-relative mb-4"
        :class="code ? 'code-input': ''"
    >
        <ValidationProvider 
            :rules="rules" 
            :name="name" 
            :vid="name"
            v-slot="{ valid, errors }"
            tag="div"
        >
            
            <label 
                :for="name"
                class="form-label"
            >   
                {{label}} 
                <span v-if="rules" 
                    class="label-red"
                >
                    *
                </span>
            </label>
            <div class="position-relative">
                <input 
                    class="form-control"
                    :class="code ? 'code-input-field' : ''" 
                    :id="name"
                    :name="name" 
                    :type="type"
                    :value="value"                
                    :state="errors[0] ? false : (valid ? true : null)"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    @input="$emit('input', $event.target.value)"
                >
                <!-- tooltip -->
                <div 
                    v-if="tooltip"
                    class="input-icon" 
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top"
                    data-bs-custom-class="custom-tooltip" 
                    :title="tooltip"
                ></div>
            <span class="apa" v-if="code">AP A</span>
               <!-- <font-awesome-icon 
                    class="text-primary" 
                    icon="info"/>-->
            </div>
            <!-- error heandling -->            
            <span class=" input-error"  v-if=" errors[0] " :class="{'active-error': errors[0]}">{{ errors[0] }}</span>
        </ValidationProvider>
    </div>    
</template>

<script>
import { Tooltip } from "bootstrap";
import { ValidationProvider } from 'vee-validate'



export default {
    name: 'CustomInput',
    components: {
            ValidationProvider        
    },
    props: {
        type: {
            required: false,
            type: String,
            default: 'text'
        },
        label: {
            required: true,
            type: String
        },
        tooltip: {
            required: false,
            type: String,
            default: null, 
        },
        value: {
            required: false,
            type: String,
            default: null
        },
        name: {
            required: false,
            type: String,
            default: null
        },
        placeholder: {
            required: false,
            type: String,
            default: null
        },
        rules: {
            required: false,
            type: [Object, String],
            default: ""
        },
        code: {
            required: false,
            type: Boolean,
            default: false
        },
        disabled:{
            required:false            
        }
    },
    created(){
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    }
}
</script>

